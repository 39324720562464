import { somar_valores_array } from "./somar_valores_array.js";
export function calculo_expressao(value_caracteres_lower_temp, nome_original) {
  var num_expressao = value_caracteres_lower_temp.join("");
  var num_expressao_original = parseInt(somar_valores_array(num_expressao));

  //console.log(value_caracteres_lower_temp);

  if (num_expressao.toString().length > 1) {
    for (let i = 1; i < 2; i = i + 0) {
      if (parseInt(num_expressao) == 11 || parseInt(num_expressao) == 22) {
        i++;
      } else {
        if (num_expressao.toString().length > 1) {
          num_expressao = parseInt(somar_valores_array(num_expressao));
        } else {
          i++;
        }
      }
    }
  }

  var valor_temp_expressao = 0;
  var valor_expressao = 0;
  var valor_reducao = 0;

  if (num_expressao == 2 || num_expressao == 4) {
    var valor_expressao_tmp = 0;

    nome_original.map((currElement, index) => {
      var valor_temp_expressao = 0;
      currElement.split().map((currElement2, index2) => {
        valor_temp_expressao += parseInt(currElement2);
      });

      if (valor_temp_expressao.toString().length > 1) {
        for (let i = 1; i < 2; i = i + 0) {
          if (valor_temp_expressao.toString().length > 1) {
            valor_temp_expressao = parseInt(
              somar_valores_array(valor_temp_expressao)
            );
          } else {
            valor_reducao += valor_temp_expressao;
            i++;
          }
        }
      }
    });
  }

  if (valor_reducao == 11 || valor_reducao == 22) {
    num_expressao = valor_reducao;
    //num_expressao_original = valor_reducao;
  }
  return [num_expressao, num_expressao_original];
}
