import styled from "styled-components";

export const Container = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    border: 2px solid ${props => props.selected ? '#25CD89' : '#16195C'};
    border-radius: 10px;
    /* topo | direita | inferior | esquerda */
    padding: 0 0 0 0;
    margin-bottom: 0px;
    align-items: center;
    cursor: pointer;

    /*&:hover {
        border: 2px solid #25CD89;
    }*/
`;

export const Icon = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /*background-color: #191A59;*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
`;

export const Info = styled.div`
    flex: 1;
    margin: 10px 10px 10px 10px;
`;

export const Title = styled.div`
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 7px;
`;

export const Description = styled.div`
    font-size: 1rem;
    color: #313131;
`;