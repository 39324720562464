function replace_letters(val) {
  var replace_nome_value = val
    .toLowerCase()
    .replace("f'", 1)
    .replace("p'", 1)
    .replace("ú'", 1)
    .replace("â'", 1)
    .replace("î'", 1)
    .replace("å'", 1)
    .replace("ñ'", 1)
    .replace("ó'", 2)
    .replace("a'", 3)
    .replace("i'", 3)
    .replace("q'", 3)
    .replace("j'", 3)
    .replace("y'", 3)
    .replace("è'", 3)
    .replace("ë'", 3)
    .replace("õ'", 3)
    .replace("b'", 4)
    .replace("k'", 4)
    .replace("r'", 4)
    .replace("à'", 4)
    .replace("ì'", 4)
    .replace("ä'", 4)
    .replace("ï'", 4)
    .replace("c'", 5)
    .replace("g'", 5)
    .replace("l'", 5)
    .replace("s'", 5)
    .replace("ù'", 5)
    .replace("á'", 5)
    .replace("í'", 5)
    .replace("ü'", 5)
    .replace("ê'", 5)
    .replace("d'", 6)
    .replace("m'", 6)
    .replace("t'", 6)
    .replace("ã'", 6)
    .replace("û'", 6)
    .replace("e'", 7)
    .replace("h'", 7)
    .replace("n'", 7)
    .replace("ò'", 7)
    .replace("ö'", 7)
    .replace("ô'", 7)
    .replace("u'", 8)
    .replace("v'", 8)
    .replace("w'", 8)
    .replace("x'", 8)
    .replace("ç'", 8)
    .replace("o'", 9)
    .replace("z'", 9)
    .replace("é'", 9)
    .replace("'", "")
    .replace("a", 1)
    .replace("i", 1)
    .replace("q", 1)
    .replace("j", 1)
    .replace("y", 1)
    .replace("è", 1)
    .replace("ë", 1)
    .replace("õ", 1)
    .replace("b", 2)
    .replace("k", 2)
    .replace("r", 2)
    .replace("à", 2)
    .replace("ì", 2)
    .replace("ä", 2)
    .replace("ï", 2)
    .replace("c", 3)
    .replace("g", 3)
    .replace("l", 3)
    .replace("s", 3)
    .replace("ù", 3)
    .replace("á", 3)
    .replace("í", 3)
    .replace("ü", 3)
    .replace("ê", 3)
    .replace("d", 4)
    .replace("m", 4)
    .replace("t", 4)
    .replace("ã", 4)
    .replace("û", 4)
    .replace("e", 5)
    .replace("h", 5)
    .replace("n", 5)
    .replace("ò", 5)
    .replace("ö", 5)
    .replace("ô", 5)
    .replace("u", 6)
    .replace("v", 6)
    .replace("w", 6)
    .replace("x", 6)
    .replace("ç", 6)
    .replace("o", 7)
    .replace("z", 7)
    .replace("é", 7)
    .replace("f", 8)
    .replace("p", 8)
    .replace("ú", 8)
    .replace("â", 8)
    .replace("î", 8)
    .replace("å", 8)
    .replace("ñ", 8)
    .replace("ó", 9);

  return replace_nome_value;
}

function replace_letters_vogais(val) {
  var replace_nome_value_vogais = val
    .toLowerCase()
    .replace("f'", "")
    .replace("p'", "")
    .replace("ú'", 1)
    .replace("â'", 1)
    .replace("î'", 1)
    .replace("å'", 1)
    .replace("ñ'", "")
    .replace("ó'", 2)
    .replace("a'", 3)
    .replace("i'", 3)
    .replace("q'", "")
    .replace("j'", "")
    .replace("y'", "")
    .replace("è'", 3)
    .replace("ë'", 3)
    .replace("õ'", 3)
    .replace("b'", "")
    .replace("k'", "")
    .replace("r'", "")
    .replace("à'", 4)
    .replace("ì'", 4)
    .replace("ä'", 4)
    .replace("ï'", 4)
    .replace("c'", "")
    .replace("g'", "")
    .replace("l'", "")
    .replace("s'", "")
    .replace("ù'", 5)
    .replace("á'", 5)
    .replace("í'", 5)
    .replace("ü'", 5)
    .replace("ê'", 5)
    .replace("d'", "")
    .replace("m'", "")
    .replace("t'", "")
    .replace("ã'", 6)
    .replace("û'", 6)
    .replace("e'", 7)
    .replace("h'", "")
    .replace("n'", "")
    .replace("ò'", 7)
    .replace("ö'", 7)
    .replace("ô'", 7)
    .replace("u'", 8)
    .replace("v'", "")
    .replace("w'", "")
    .replace("x'", "")
    .replace("ç'", "")
    .replace("o'", 9)
    .replace("z'", "")
    .replace("é'", 9)
    .replace("'", "")
    .replace("a", 1)
    .replace("i", 1)
    .replace("q", "")
    .replace("j", "")
    .replace("y", "")
    .replace("è", 1)
    .replace("ë", 1)
    .replace("õ", 1)
    .replace("b", "")
    .replace("k", "")
    .replace("r", "")
    .replace("à", 2)
    .replace("ì", 2)
    .replace("ä", 2)
    .replace("ï", 2)
    .replace("c", "")
    .replace("g", "")
    .replace("l", "")
    .replace("s", "")
    .replace("ù", 3)
    .replace("á", 3)
    .replace("í", 3)
    .replace("ü", 3)
    .replace("ê", 3)
    .replace("d", "")
    .replace("m", "")
    .replace("t", "")
    .replace("ã", 4)
    .replace("û", 4)
    .replace("e", 5)
    .replace("h", "")
    .replace("n", "")
    .replace("ò", 5)
    .replace("ö", 5)
    .replace("ô", 5)
    .replace("u", 6)
    .replace("v", "")
    .replace("w", "")
    .replace("x", "")
    .replace("ç", "")
    .replace("o", 7)
    .replace("z", "")
    .replace("é", 7)
    .replace("f", "")
    .replace("p", "")
    .replace("ú", 8)
    .replace("â", 8)
    .replace("î", 8)
    .replace("å", 8)
    .replace("ñ", "")
    .replace("ó", 9);

  return replace_nome_value_vogais;
}

function replace_letters_consoantes(val) {
  var replace_nome_value_consoantes = val
    .toLowerCase()
    .replace("f'", 1)
    .replace("p'", 1)
    .replace("ú'", "")
    .replace("â'", "")
    .replace("î'", "")
    .replace("å'", "")
    .replace("ñ'", 1)
    .replace("ó'", "")
    .replace("a'", "")
    .replace("i'", "")
    .replace("q'", 3)
    .replace("j'", 3)
    .replace("y'", 3)
    .replace("è'", "")
    .replace("ë'", "")
    .replace("õ'", "")
    .replace("b'", 4)
    .replace("k'", 4)
    .replace("r'", 4)
    .replace("à'", "")
    .replace("ì'", "")
    .replace("ä'", "")
    .replace("ï'", "")
    .replace("c'", 5)
    .replace("g'", 5)
    .replace("l'", 5)
    .replace("s'", 5)
    .replace("ù'", "")
    .replace("á'", "")
    .replace("í'", "")
    .replace("ü'", "")
    .replace("ê'", "")
    .replace("d'", 6)
    .replace("m'", 6)
    .replace("t'", 6)
    .replace("ã'", "")
    .replace("û'", "")
    .replace("e'", "")
    .replace("h'", 7)
    .replace("n'", 7)
    .replace("ò'", "")
    .replace("ö'", "")
    .replace("ô'", "")
    .replace("u'", "")
    .replace("v'", 8)
    .replace("w'", 8)
    .replace("x'", 8)
    .replace("ç'", 8)
    .replace("o'", "")
    .replace("z'", 9)
    .replace("é'", "")
    .replace("'", "")
    .replace("a", "")
    .replace("i", "")
    .replace("q", 1)
    .replace("j", 1)
    .replace("y", 1)
    .replace("è", "")
    .replace("ë", "")
    .replace("õ", "")
    .replace("b", 2)
    .replace("k", 2)
    .replace("r", 2)
    .replace("à", "")
    .replace("ì", "")
    .replace("ä", "")
    .replace("ï", "")
    .replace("c", 3)
    .replace("g", 3)
    .replace("l", 3)
    .replace("s", 3)
    .replace("ù", "")
    .replace("á", "")
    .replace("í", "")
    .replace("ü", "")
    .replace("ê", "")
    .replace("d", 4)
    .replace("m", 4)
    .replace("t", 4)
    .replace("ã", "")
    .replace("û", "")
    .replace("e", "")
    .replace("h", 5)
    .replace("n", 5)
    .replace("ò", "")
    .replace("ö", "")
    .replace("ô", "")
    .replace("u", "")
    .replace("v", 6)
    .replace("w", 6)
    .replace("x", 6)
    .replace("ç", 6)
    .replace("o", "")
    .replace("z", 7)
    .replace("é", "")
    .replace("f", 8)
    .replace("p", 8)
    .replace("ú", "")
    .replace("â", "")
    .replace("î", "")
    .replace("å", "")
    .replace("ñ", 8)
    .replace("ó", "");

  return replace_nome_value_consoantes;
}

export function tratando_nome(nome, nascimento) {
  var string = "Variaveis iniciais js" + nome + nascimento;

  var nome_sem_espacos = [];
  var nome_value_sem_espaco = [];

  var nome_array = nome.split("");

  var nome_array_value = [];
  var nome_consoantes_array_value = [];
  var nome_vogais_array_value = [];
  var nome_consoantes_array = [];
  var nome_vogais_array = [];

  var nome_value = "";
  var nome_consoantes_value = "";
  var nome_vogais_value = "";

  var valor_letter = "";
  var valor_letter_consoantes = "";
  var valor_letter_vogais = "";

  nome_array.map((currElement, index) => {
    if (nome_array[index + 1] == "'") {
      if (nome_array[index] != "'") {
        valor_letter = replace_letters(currElement + nome_array[index + 1]);
        valor_letter_consoantes = replace_letters_consoantes(
          currElement + nome_array[index + 1]
        );
        valor_letter_vogais = replace_letters_vogais(
          currElement + nome_array[index + 1]
        );

        nome_array_value.push(valor_letter);
        nome_consoantes_array_value.push(valor_letter_consoantes);
        nome_vogais_array_value.push(valor_letter_vogais);

        nome_value += valor_letter;
        nome_consoantes_value += valor_letter_consoantes;
        nome_vogais_value += valor_letter_vogais;

        if (valor_letter_vogais > 0) {
          nome_vogais_array.push(currElement + nome_array[index + 1]);
        }
        if (valor_letter_consoantes > 0) {
          nome_consoantes_array.push(currElement + nome_array[index + 1]);
        }

        if (currElement != " ") {
          nome_sem_espacos.push(currElement + nome_array[index + 1]);
          nome_value_sem_espaco.push(valor_letter);
        }
      }
    } else {
      if (currElement != "'") {
        valor_letter = replace_letters(currElement);
        valor_letter_consoantes = replace_letters_consoantes(currElement);
        valor_letter_vogais = replace_letters_vogais(currElement);

        nome_array_value.push(valor_letter);
        nome_consoantes_array_value.push(valor_letter_consoantes);
        nome_vogais_array_value.push(valor_letter_vogais);

        nome_value += valor_letter;
        nome_consoantes_value += valor_letter_consoantes;
        nome_vogais_value += valor_letter_vogais;

        if (valor_letter_vogais > 0) {
          nome_vogais_array.push(currElement);
        }
        if (valor_letter_consoantes > 0) {
          nome_consoantes_array.push(currElement);
        }

        if (currElement != " ") {
          nome_sem_espacos.push(currElement);
          nome_value_sem_espaco.push(valor_letter);
        }
      }
    }
  });

  var caracteres = nome_value_sem_espaco;
  var nome_valor_original_array = nome_value.split(" ");

  var input_tabela_viver_de_mapa = [
    nome_sem_espacos, //0
    nascimento, //1
    nome_value_sem_espaco, //2
    nome_valor_original_array, //3
    nome, //4
    caracteres, //5
    nome_consoantes_value, //6
    nome_vogais_value, //7
    nome_consoantes_array, //8
    nome_vogais_array, //9
    nome_consoantes_array_value, //10
    nome_vogais_array_value, //11
    //marcador
    nome_value,
  ];

  return input_tabela_viver_de_mapa;
}
