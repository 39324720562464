import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import "./styles.css";
import Depoimentos from "./stickers/depoimentos.jsx"
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SelectOption } from './SelectOption';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { blue, pink } from '@mui/material/colors';
import { tratando_nome } from "./functions/tratando_nome.js"
import { triangulo } from "./functions/triangulo2.jsx";
import { calculo_destino } from "./functions/calculo_destino.js";
import { calculo_expressao } from "./functions/calculo_expressao.js";
import { calculo_arcanos_vibracao_regente } from "./functions/calculo_arcanos_vibracao_regente.js";
import { arcanos_table } from "./functions/arcanos_table.js";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/joy/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import ReactDOM from 'react-dom';
import { useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { Linking } from 'react-native';
import minhaImagem from './assets/icons8-estrela-de-david_4.png';
import { IMaskInput } from 'react-imask';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styled, { keyframes } from 'styled-components';
import { format, parse, addDays, isValid } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import amorSvg from "./assets/amor.svg";
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { TextFieldProps } from '@mui/material/TextField';
import Corrente from './assets/Correntess.jpg';

import ReactGA from "react-analytics-ga4";
import GA4 from "google-analytics-ga4";

/*// Send a custom event
ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
});*/

const colorTransition = keyframes`
0% { color: #ff0000; } /* Cor inicial */
50% { color: #00ff00; } /* Cor intermediária */
100% { color: #0000ff; } /* Cor final */
`;
const ChangingText = styled.h1`
animation: ${colorTransition} 5s linear infinite;
`;


export default function App() {

  function sendRequestToPHP(step) {
    // Obtendo o IP do cliente
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const clientIP = data.ip;
  
        // Dados a serem enviados no corpo da solicitação
        const requestData = {
          ip: clientIP,
          step: step,
        };
  
        const url = 'https://numerologiacabalistica.app.br/ganalytics/index.php';
  
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        };
  
        // Realiza a solicitação usando fetch
        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            console.log('Resposta do servidor:', response);
            // Faça o que for necessário com a resposta
          })
          .catch((error) => {
            console.error('Erro na solicitação:', error);
            // Lidar com erros, se houver
          });
      })
      .catch((error) => {
        console.error('Erro ao obter o IP:', error);
        // Lidar com erros, se houver
      });
  }

// Verifique se o passo 1 já foi concluído no armazenamento local
if (localStorage.getItem('step1') !== 'concluido') {
  // Se ainda não estiver concluído, faça a inserção e marque como concluído
  sendRequestToPHP('step1');
  // Marque o passo 1 como concluído no armazenamento local
  localStorage.setItem('step1', 'concluido');
}
  
  


  const [congelado, setCongelado] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState({
    day: '',
    month: '',
    year: ''});
  const [formattedDate, setFormattedDate] = React.useState('');
  const [matriz, setMatriz] = useState([]);
  const [destino, setDestino] = useState(null);
  const [expressao, setExpressao] = useState(null);
  const [arcanosVibracaoRegente, setArcanosVibracaoRegente] = useState(null);
  const [valuedate, setValuedate] = React.useState<Dayjs | null>(dayjs('2000-01-01'));
  const { prev, next, jump, total, current, progress } = useSteps();
  const [user, setUser] = useState<User>({ name: "", email: "", });
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const tableRef = useRef(null);
  const [checkboxChecked, setCheckboxChecked] = React.useState(true);
  const [level, setLevel] = React.useState(0);

  const [phone, setPhone] = useState('');

  useEffect(() => {
    handleDateChange();
  }, [selectedDate]); // Executar sempre que o selectedDate for alterado

  const handleDateChange = () => {
    const { day, month, year } = selectedDate;
    const parsedDate = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
    if (isValid(parsedDate)) {
      const nextDay = addDays(parsedDate, 0);
      setFormattedDate(format(nextDay, 'dd/MM/yyyy'));

      setCongelado(false); // Congele o botão após a validação
      //setValuedate(format(nextDay, 'dd/MM/yyyy'))
      
      //console.log(nextDay); ///// VARIAVEL DO DIA DO NASCIMENTO
    } else {
      setFormattedDate('');
      setCongelado(true); // Congele o botão após a validação
    }
  };

  /*const handleFieldChange = (event, field) => {
    const { value } = event.target;
    setSelectedDate((prevSelectedDate) => ({
      ...prevSelectedDate,
      [field]: value
    }));
  };*/

  //const [formattedDate, setFormattedDate] = useState('');

  const validateDate = (date) => {
    const { day, month, year } = date;
  
    // Verifique se os campos do dia, mês e ano estão preenchidos
    if (!day || !month || !year) {
      return false;
    }
  
    // Converta os campos para números
    const numericDay = parseInt(day, 10);
    const numericMonth = parseInt(month, 10);
    const numericYear = parseInt(year, 10);
  
    // Faça as validações necessárias com os valores numéricos
    // Por exemplo, verifique se o dia é válido para o mês e ano especificados
    // e se o mês e o ano estão dentro de um intervalo válido
  
    // Retorne true se a data for válida, caso contrário, retorne false
  };

  const formatDate = (date) => {
    const { day, month, year } = date;
    return `${day}/${month}/${year}`;
  };

  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    const updatedDate = { ...selectedDate, [field]: value };
    const dateIsValid = validateDate(updatedDate);

    setSelectedDate(updatedDate);

    if (dateIsValid) {
      const formatted = formatDate(updatedDate);
      setFormattedDate(formatted);
    } else {
      setFormattedDate('');
    }
  };

  const setInitialZoom = useCallback(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      const screenWidth = window.innerWidth;
      const initialZoom = screenWidth / tableWidth;
      setZoomLevel(initialZoom);
    }
  }, [tableRef]);

  useEffect(() => {
    window.addEventListener('resize', setInitialZoom);
    return () => {
      window.removeEventListener('resize', setInitialZoom);
    };
  }, [setInitialZoom]);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(Math.max(0.1, zoomLevel - 0.1));
  };

  const handleTableLoad = () => {
    setIsTableLoaded(true);
  };

  function scrollToElement(element) {
    return new Promise((resolve, reject) => {
      if (!element) {
        reject(new Error('Element not found'));
        return;
      }
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': element.offsetTop
      });
      window.setTimeout(() => {
        resolve();
      }, 1000); // tempo em milissegundos para esperar antes de resolver a Promise
    });
  }
  
  async function loadAndScroll() {
    const divElement = document.getElementById('titulo_do_site');
    try {
      await scrollToElement(divElement);
      // prossegue com o código
    } catch (error) {
      console.error(error);
    }
  }

const handlePhoneChange = (event) => {
  setPhone(event.target.value);
};

  const handleChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  function salvando_dados() {
/*
// Multiple products (previously known as trackers)
ReactGA.initialize("GTM-5TFM8FB");
    // Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
*/




// Verifique se o passo 1 já foi concluído no armazenamento local
if (localStorage.getItem('step4') !== 'concluido') {
  // Se ainda não estiver concluído, faça a inserção e marque como concluído
  sendRequestToPHP('step4');
  // Marque o passo 1 como concluído no armazenamento local
  localStorage.setItem('step4', 'concluido');
}

    loadAndScroll();
    next();
    handleStepLoad();
  }


  function validando_dados() {


    GA4.initialize("GTM-5TFM8FB");
    GA4.send("captação de usuário");


    if(user.name == '' || user.email == '') {
      return (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Preencha todos os campos — <strong>Necessário o preenchimento corretamente!</strong>
          </Alert>
      </Stack>
      );
    }
    else {
// Verifique se o passo 1 já foi concluído no armazenamento local
if (localStorage.getItem('step3') !== 'concluido') {
  // Se ainda não estiver concluído, faça a inserção e marque como concluído
  sendRequestToPHP('step3');
  // Marque o passo 1 como concluído no armazenamento local
  localStorage.setItem('step3', 'concluido');
}
      loadAndScroll();
      next();
    }
  }
  
  function passar_pagina() {

// Verifique se o passo 1 já foi concluído no armazenamento local
if (localStorage.getItem('step2') !== 'concluido') {
  // Se ainda não estiver concluído, faça a inserção e marque como concluído
  sendRequestToPHP('step2');
  // Marque o passo 1 como concluído no armazenamento local
  localStorage.setItem('step2', 'concluido');
}
      loadAndScroll();
      next();
  }

  function voltar_pagina() {
    loadAndScroll();
    prev();
  }

  function ultima_pagina() {
// Verifique se o passo 1 já foi concluído no armazenamento local
if (localStorage.getItem('conversao') !== 'concluido') {
  // Se ainda não estiver concluído, faça a inserção e marque como concluído
  sendRequestToPHP('www.maxnumerologia.com.br');
  // Marque o passo 1 como concluído no armazenamento local
  localStorage.setItem('conversao', 'concluido');
}

    window.open('https://numerologiacabalistica.app.br/ganalytics/8D8D68BA3B232896581FD2619E9190949C180463707A59755B7979B78D48D25D.php');
  }

  const buttonStyle = {
    fontFamily: "sora, sans-serif", // Define a fonte
    fontSize: '1.1em', // Define o tamanho da fonte
    fontWeight: 700, // Define o peso da fonte
    letterSpacing: '0.1em', // Define o espaçamento entre caracteres
    backgroundColor: '#545CC7',
    color: 'white', // Define a cor do texto como branca
    padding: '20px 80px 20px 80px'
  };

  const buttonStyleIniciar = {
    fontFamily: "sora, sans-serif", // Define a fonte
    fontSize: '1.1em', // Define o tamanho da fonte
    fontWeight: 700, // Define o peso da fonte
    letterSpacing: '0.1em', // Define o espaçamento entre caracteres
    backgroundColor: '#545CC7',
    color: 'white', // Define a cor do texto como branca
    padding: '20px 20px 20px 20px',
    width: '20em', // Defina a largura desejada aqui
  };

  const buttonStyleAurea = {
    fontFamily: "sora, sans-serif", // Define a fonte
    fontSize: '1.1em', // Define o tamanho da fonte
    fontWeight: 700, // Define o peso da fonte
    letterSpacing: '0.1em', // Define o espaçamento entre caracteres
    backgroundColor: '#33ab1b',
    color: 'white', // Define a cor do texto como branca
    padding: '20px 80px 20px 80px',
    width: '20em', // Defina a largura desejada aqui
  };

// criar funções de botoes
function botoes(num:any) {
  if(num=="1"){
    return (
      <div>
        <Box>
          <Button size="lg" variant="solid" style={buttonStyleIniciar}

          onClick={passar_pagina}>INICIAR TESTE AGORA!</Button>
        </Box>
      </div>)
  }
  if(num=="2"){
    return (
      <div>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button size="lg" variant="solid" style={buttonStyle} onClick={voltar_pagina}>Voltar</Button>
          <Button size="lg" variant="solid" style={buttonStyle} disabled={congelado} onClick={validando_dados}>Próximo</Button>
        </Box>
      </div>)
  }
  if(num=="3"){
    return (
      <div>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button size="lg" variant="solid" style={buttonStyle} onClick={voltar_pagina}>Voltar</Button>
          <Button size="lg" variant="solid" style={buttonStyle} onClick={salvando_dados}>Próximo</Button>
        </Box>
      </div>)
  }  
  if(num=="4"){
    return (
      <div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
          <Button className="highlighted-button" style={buttonStyleAurea} size="lg" variant="solid" onClick={ultima_pagina}>Quero saber mais sobre o mapa numerologico?</Button>
          {/*<Button size="lg" variant="solid" style={buttonStyle} onClick={voltar_pagina}>Voltar</Button>*/}
        </Box>
      </div>)
  }
  if(num=="5"){
    return (
      <div>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button size="lg" variant="solid" onClick={voltar_pagina}>Voltar</Button>
          <Button size="lg" variant="solid" onClick={ultima_pagina}>Mais informações</Button>
        </Box>
      </div>)
  }
  //<button onClick={() => jump(2)}>Jump to Step 2</button>
}

function handleChangecheckBox(event) {
  setCheckboxChecked(event.target.checked);
}

function getSelectedOptionValue() {
  if (level === 0) {
    return "Amor";
  } else if (level === 1) {
    return "Dinheiro";
  } else if (level === 2) {
    return "Saúde";
  }
}

function handleStepLoad() {

  var val = tratando_nome(user.name);
  
  var nome_sem_espacos_input = val[0];
  var nascimento_input = valuedate.format('YYYY-MM-DD');//val[1];
  var nome_value_sem_espaco_input = val[2];
  var nome_valor_original_array_input = val[3];
  var nome_original_espacos_input = val[4];
  var valores_caracteres_input = val[5];
  var nome_consoantes_value_input = val[6];
  var nome_vogais_value_input = val[7];
  var nome_consoantes_array_input = val[8]; //8
  var nome_vogais_array_input = val[9]; //9
  var nome_consoantes_array_value_input = val[10]; //10
  var nome_vogais_array_value_input = val[11];

  var nascimento_split =  valuedate.format('YYYY-MM-DD');

  var tabela_arcano = arcanos_table(valores_caracteres_input); //Função que lista arcanos

  var arcanos = tabela_arcano[0]; //Tabela que lista todos os arcanos restantes
  var arcano_temp = tabela_arcano[1]; //Lista com os arcanos restantes a partir do atual
  var arcano_tabela_input = tabela_arcano[2]; //Lista com todos os arcanos do nome sem repetição

  var matriz_triangulo = triangulo(nome_sem_espacos_input, valores_caracteres_input);
  setMatriz(matriz_triangulo);

    var toda_a_sequencia = matriz_triangulo[1]; //Listando em array todos os números da tabela triângulo

    //const temp_destino = calculo_destino(nascimento_split);
    const temp_destino = calculo_destino([selectedDate.year,selectedDate.month,selectedDate.day]);
    setDestino(temp_destino);
    var num_destino = temp_destino[0]; //Valor destino
    var num_destino_original = temp_destino[1]; //Valor destino original


    const temp_expressao = calculo_expressao(valores_caracteres_input, nome_valor_original_array_input);
    setExpressao(temp_expressao);
    var num_expressao = temp_expressao[0]; //Valor expressão
    var num_expressao_original = temp_expressao[1]; //Valor expressão original

    const tempArcanosVibracaoRegente = calculo_arcanos_vibracao_regente(
      toda_a_sequencia,
      arcano_temp,
      selectedDate.year+'-'+selectedDate.month+'-'+selectedDate.day,
      arcano_tabela_input
    );

    setArcanosVibracaoRegente(tempArcanosVibracaoRegente);
    var arcano_vibracao = tempArcanosVibracaoRegente[0]; //Arcano de vibração
    var arcano_regente = tempArcanosVibracaoRegente[1]; //Arcano regente
    var idx_arcano_vibr = tempArcanosVibracaoRegente[2]; //Indice do arcano de vibração na lista de arcanos

var tag_seq_negativa = '';
if(matriz_triangulo[2].toString()!=='')
{
    tag_seq_negativa = 'Possui sequência negativa';
}

const options = {
  method: 'POST',
  headers: {accept: 'application/json', 'Content-Type': 'application/json'},
  body: JSON.stringify({
    event_type: 'CONVERSION',
    event_family: 'CDP',
    payload: {
      conversion_identifier: 'o_poder_do_seu_nome',
      email: user.email,
      tags: ['captado no site','formulário http://numerologiacabalistica.app.br/',tag_seq_negativa,checkboxChecked ? "receber email" : "não receber email"],
      name: user.name,
      //cf_data_de_nascimento: valuedate.format('YYYY-MM-DD').toString(),
      cf_data_de_nascimento: formattedDate.toString(),
      cf_interesses: getSelectedOptionValue()?.toString(),
      cf_sequencias_negativas: matriz_triangulo[3].toString(),
      cf_celular_wp: phone,
      cf_receber_emails: checkboxChecked ? "receber email" : "não receber email"
  }
  })
};

fetch('https://api.rd.services/platform/conversions?api_key=42a135384dfac98afbe627277e27dbfc', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));
/*
  const options = {
    method: 'POST',
    headers: {accept: 'application/json', 'content-type': 'application/json'},
    body: JSON.stringify({event: 'conversion',
      key: '9feea0fc6e90b4c81bd98ad63f8ad223a68eb15907cd07e5cf6540f101dd140ab51211db',
      eventdata: JSON.stringify({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        conversion_identifier: 'o_poder_do_seu_nome',
        email: user.email,
        tags: ['captado no site', 'formulário http://numerologiacabalistica.app.br/', tag_seq_negativa, checkboxChecked ? "receber email" : "não receber email"],
        name: user.name,
        cf_data_de_nascimento: formattedDate.toString(),
        cf_interesses: getSelectedOptionValue()?.toString(),
        cf_sequencias_negativas: matriz_triangulo[3].toString(),
        cf_celular_wp: phone,
        cf_receber_emails: checkboxChecked ? "receber email" : "não receber email"
      }),
    }),
  };
  
  fetch('https://maxneto.api-us1.com/api/3/groupMembers', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));*/
  
/*

  const apiUrl = "https://maxneto.api-us1.com/api/3/accountCustomFieldMeta";
  const apiKey = "9feea0fc6e90b4c81bd98ad63f8ad223a68eb15907cd07e5cf6540f101dd140ab51211db";
  
  const contact = {
    api_action: "contact_sync",
    api_key: apiKey,
    email: "test@example.com",
    first_name: "Test", // optional
    last_name: "User", // optional
    // additional fields from http://www.activecampaign.com/api/example.php?call=contact_sync
  };
  
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(contact),
  };
  
  fetch(apiUrl, options)
    .then(response => response.json())
    .then(response => console.log("Response: ", response))
    .catch(err => console.error(err));
  */
/*
  const options = {
    method: 'POST',
    headers: {accept: 'application/json', 'content-type': 'application/json',
    'Api-Token': '9feea0fc6e90b4c81bd98ad63f8ad223a68eb15907cd07e5cf6540f101dd140ab51211db'}, // Replace with your actual API key},
    body: JSON.stringify({
      accountCustomFieldMetum: {
        isRequired: 0,
        fieldOptions: [
          'CONVERSION',
          'CDP',
          'o_poder_do_seu_nome',
          'user.email',
          'captado no site',
          'formulário http://numerologiacabalistica.app.br/',
          'tag_seq_negativa',
          'checkboxChecked ? "receber email" : "não receber email"',
          'user.name',
          'formattedDate.toString()',
          'getSelectedOptionValue()?.toString()',
          'matriz_triangulo[3].toString()',
          'phone',
          'checkboxChecked ? "receber email" : "não receber email"'
        ],
        fieldType: 'text',
        fieldLabel: 'eventdata'
      }
    })
  };
  
  fetch('https://maxneto.api-us1.com/api/3/accountCustomFieldMeta', options)
    .then(response => response.json())
    .then(response => console.log(response))
    .catch(err => console.error(err));*/






/*  TRECHO FUNCIONANDO ACTIVE CAMPAIN
    const url = 'https://numerologiacabalistica.app.br/proxy/proxy.php';
    
    const data = {
      email: user.email,
      //tags: ['captado no site', 'formulário http://numerologiacabalistica.app.br/', 'tag_seq_negativa', checkboxChecked ? 'receber email' : 'não receber email'],
      name: user.name,
      cf_data_de_nascimento: formattedDate.toString(),
      cf_interesses: getSelectedOptionValue()?.toString(), // Substitua pelo valor real
      cf_sequencias_negativas: matriz_triangulo[3].toString(), // Substitua pelo valor real
      cf_celular_wp: phone, // Substitua pelo valor real
      cf_receber_emails: checkboxChecked ? "receber email" : "não receber email",
    };
    
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Resposta do servidor:', data);
      })
      .catch(error => {
        console.error('Erro na requisição:', error);
      });
*/


}

//******************************************************************* */
function ProgressBar({ current, total }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculatedProgress = (current / total) * 100;
    setProgress(calculatedProgress);
  }, [current, total]);

  var progressBarStyle = {
    background: `linear-gradient(to right, #545dc6 ${progress}%, #fff ${progress}%)`,
    transition: "width 0.2s ease-in-out", // Adiciona uma transição suave à mudança de fundo
  };

  return (
    <div className="progress-container">
      <div className="text-element-progress">Progresso: {current} / {total} ({Math.round(progress)}%)</div>
      <div className="progress-bar" style={progressBarStyle}>
        <span className="progress-bar-text">{Math.round(progress)}%</span>
      </div>
    </div>
  );
}

//******************************************************************* */

  return (
    <div id="titulo_do_site" className="steps_wrapper">

      <ProgressBar current={current} total={total} />

        <img className="star-logo-img" src={minhaImagem} alt="Descrição da imagem" />

      
      <div className="intro-text">
        <b  style={{ justifyContent: 'top', alignItems: 'flex-end', verticalAlign:'top', fontSize: '36px' }}>
          Prof. Max Numerologia<br />
          Teste Rápido
        </b>
        <br />
        <b className="logo-text">O PODER DO SEU NOME</b>
        <br />
        <b className="intro-sub-text">Venha descobrir os segredos da sua vida através dos números.</b>
      </div>

      <Steps>
        <div className="step">
          
        <br /><br />
          <div className="depoimentos_box">
            <Depoimentos />
          </div>
        </div>
        <div className="step">
        <div className="step_two">

          <div className="form">
            <h1>Para receber o resultado, digite os dados.</h1>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                id="name"
                name="name"
                value={user.name}
                onChange={handleChange} 
                label="Nome"
                helperText=""
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
                 />
              <br />
              <TextField
                fullWidth
                multiline
                variant="outlined"
                id="email"
                name="email"
                value={user.email}
                onChange={handleChange} 
                label="Email"
                helperText=""
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
                 />
            <br />
              <TextField
                variant="outlined"
                id="phone"
                name="phone"
                label="Whatsapp"
                helperText=""
                value={phone}
                onChange={handlePhoneChange}
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
              />
             <br />
              <div className="birth-field">
              <TextField
                label="Dia"
                type="number"
                value={selectedDate.day}
                onChange={(e) => handleFieldChange(e, 'day')}
                inputProps={{ min: 1, max: 31 }}
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
              />
              <TextField
                label="Mês"
                type="number"
                value={selectedDate.month}
                onChange={(e) => handleFieldChange(e, 'month')}
                inputProps={{ min: 1, max: 12 }}
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
              />
              <TextField
                label="Ano"
                type="number"
                value={selectedDate.year}
                onChange={(e) => handleFieldChange(e, 'year')}
                inputProps={{ min: 1 }}
                InputLabelProps={{
                  style: {
                    transform: 'translate(10px, -35px)', // Ajuste a posição vertical conforme necessário
                    fontFamily: '"sora", sans-serif', // Defina a fonte desejada
                    fontWeight: 400, // Defina o peso da fonte desejado
                    fontSize: '16px', // Defina o tamanho da fonte desejado
                  },
                }}
              />
            </div>
              {formattedDate ? (
                <p>
                  Data selecionada: {formattedDate}
                </p>
              ) : (
                <p style={{ color: 'red' }}>
                  Data inválida!
                </p>
              )}
              <div role="main" id="opoderdoseunome-34cf590a461ad28374a2"></div>
              <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox
                          sx={{
                          color: blue[800],
                          '&.Mui-checked': {
                            color: blue[600],
                          },
                        }}
                        checked={checkboxChecked}  
                        onChange={handleChangecheckBox}
                        />}
                        label="Entendo que as sequências negativas impactam a minha vida e desejo receber orientações!"
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
          </div>
          <div className="step_two_opt">
          <SelectOption
                title="Amor"
                description="Quero destravar o amor na minha vida!"
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="#545CC7" width="800px" height="800px" viewBox="0 0 24 24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M20.5,4.609A5.811,5.811,0,0,0,16,2.5a5.75,5.75,0,0,0-4,1.455A5.75,5.75,0,0,0,8,2.5,5.811,5.811,0,0,0,3.5,4.609c-.953,1.156-1.95,3.249-1.289,6.66,1.055,5.447,8.966,9.917,9.3,10.1a1,1,0,0,0,.974,0c.336-.187,8.247-4.657,9.3-10.1C22.45,7.858,21.453,5.765,20.5,4.609Zm-.674,6.28C19.08,14.74,13.658,18.322,12,19.34c-2.336-1.41-7.142-4.95-7.821-8.451-.513-2.646.189-4.183.869-5.007A3.819,3.819,0,0,1,8,4.5a3.493,3.493,0,0,1,3.115,1.469,1.005,1.005,0,0,0,1.76.011A3.489,3.489,0,0,1,16,4.5a3.819,3.819,0,0,1,2.959,1.382C19.637,6.706,20.339,8.243,19.826,10.889Z"></path></g></svg>}
                selected={level===0}
                onClick={()=>setLevel(0)}
            />

            <SelectOption
                title="Dinheiro"
                description="Quero saber o que fazer para ganhar mais dinheiro!"
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g clip-path="url(#clip0_443_3628)"><rect x="2" y="6" width="20" height="12" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></rect><path d="M22 10C21.4747 10 20.9546 9.89654 20.4693 9.69552C19.984 9.4945 19.543 9.19986 19.1716 8.82843C18.8001 8.45699 18.5055 8.01604 18.3045 7.53073C18.1035 7.04543 18 6.52529 18 6L22 6L22 10Z" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 18C18 16.9391 18.4214 15.9217 19.1716 15.1716C19.9217 14.4214 20.9391 14 22 14L22 18L18 18Z" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 14C3.06087 14 4.07828 14.4214 4.82843 15.1716C5.57857 15.9217 6 16.9391 6 18L2 18L2 14Z" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 6C6 7.06087 5.57857 8.07828 4.82843 8.82843C4.07828 9.57857 3.06087 10 2 10L2 6H6Z" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.0741 9.5H11.3333C10.597 9.5 10 10.0596 10 10.75C10 11.4404 10.597 12 11.3333 12H13.1111C13.8475 12 14.4444 12.5596 14.4444 13.25C14.4444 13.9404 13.8475 14.5 13.1111 14.5H10" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 9.51733V8.5" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 15.5173V14.5" stroke="#545CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_443_3628"><rect width="24" height="24" fill="white"></rect></clipPath></defs></g></svg>}
                selected={level===1}
                onClick={()=>setLevel(1)}
            />

            <SelectOption
                title="Saúde"
                description="Quero cuidar melhor da minha saúde!"
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none" stroke="#545CC7"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M8.96173 19.3786L8.48914 19.961L8.96173 19.3786ZM12 5.57412L11.4522 6.08635C11.594 6.23803 11.7923 6.32412 12 6.32412C12.2077 6.32412 12.406 6.23803 12.5478 6.08635L12 5.57412ZM15.0383 19.3787L15.5109 19.961L15.0383 19.3787ZM12 21L12 20.25L12 21ZM9.43432 18.7963C7.98445 17.6197 6.29166 16.077 4.96771 14.3884C3.62931 12.6814 2.75 10.9289 2.75 9.3175H1.25C1.25 11.4354 2.38041 13.5196 3.78729 15.3139C5.20863 17.1267 6.99671 18.7499 8.48914 19.961L9.43432 18.7963ZM2.75 9.3175C2.75 6.41289 4.01766 4.61731 5.58602 4.00319C7.15092 3.39043 9.34039 3.82778 11.4522 6.08635L12.5478 5.06189C10.1598 2.50784 7.34924 1.70187 5.0391 2.60645C2.73242 3.50967 1.25 5.99209 1.25 9.3175H2.75ZM15.5109 19.961C17.0033 18.7499 18.7914 17.1268 20.2127 15.314C21.6196 13.5196 22.75 11.4354 22.75 9.31747H21.25C21.25 10.9289 20.3707 12.6814 19.0323 14.3884C17.7084 16.077 16.0156 17.6197 14.5657 18.7963L15.5109 19.961ZM22.75 9.31747C22.75 5.99208 21.2676 3.50966 18.9609 2.60645C16.6508 1.70187 13.8402 2.50784 11.4522 5.06189L12.5478 6.08635C14.6596 3.82778 16.8491 3.39042 18.414 4.00319C19.9823 4.6173 21.25 6.41287 21.25 9.31747H22.75ZM8.48914 19.961C9.76058 20.9928 10.6423 21.75 12 21.75L12 20.25C11.2771 20.25 10.8269 19.9263 9.43432 18.7963L8.48914 19.961ZM14.5657 18.7963C13.1731 19.9263 12.7229 20.25 12 20.25L12 21.75C13.3577 21.75 14.2394 20.9928 15.5109 19.961L14.5657 18.7963Z" fill="#545CC7"></path><path d="M18.5 9.00002H16.5M16.5 9.00002L14.5 9.00002M16.5 9.00002L16.5 7M16.5 9.00002L16.5 11" stroke="#545CC7" stroke-width="1.5" stroke-linecap="round"></path></g></svg>}
                selected={level===2}
                onClick={()=>setLevel(2)}
            />
            </div>
        </div>
        </div>

        <div className="step">

          <div className="flex-step-result">
            <span className="name-step-result">{user.name}, estou ADMIRADO COM AS SUAS RESPOSTAS!</span>
            <span className="text-step-result">O fato de você ter nascido em {selectedDate.day}/{selectedDate.month}/{selectedDate.year} diz muita coisa sobre você e seu futuro.</span>
          </div>

          <div className="flex-step-result-3">
            <span className="text-step-3">Está nítido que existe um <b>bloqueio na sua vida, quando o assunto é {getSelectedOptionValue()}.</b></span>
            <span className="text-step-4">Por isso venha descobrir comigo como destravar a sua vida!!!</span>
          </div>


            <img className="img-corrente" src={Corrente} alt="Corrente" />


          <div className="flex-step-result-3">
            <div className="text-step-3"><span>Mas antes de revelar mais coisas preciso que entenda o seguinte:</span></div>
            <div className="text-step-3"><span>A sua <b>DATA DE NASCIMENTO</b> e seu <b>NOME</b> são a chave para que eu possa te<br></br>orientar pelo caminho certo, mas o que está dentro de você é o que vai<br></br><b>DESBLOQUEAR</b> a sua vida.</span></div>
            <div className="text-step-3"><span>ESSE BLOQUEIO PODE SER POR DOIS MOTIVOS:</span></div>
          </div>
          <div className="flex-box-6">
            <div className="text-step-6">
                <svg aria-hidden="true" className="svg-x" viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
                <span>A energia essencial do dia {selectedDate.day}/{selectedDate.month}/{selectedDate.year}</span>
            </div>
            <div className="text-step-6">
                <svg aria-hidden="true" className="svg-x" viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
                <span>E com a sua bagagem espiritual que está nessa data</span>
            </div>
          </div>
          <div className="flex-box-7">
            <div className="text-step-3">
                <b>ESTÁ NA HORA DE COLHER TUDO O QUE VOCÊ MERECE!</b>
              </div>
              <div className="text-step-3">
                <span>VOU ENVIAR PARA VOCÊ UM RELATÓRIO COMPLETO COM AS RESPOSTAS QUE VOCÊ PRECISA!!!</span>
              </div>
              <div className="text-step-3">
                <span>Se você quiser os <b>resultados</b> do seu teste agora <b>siga em frente!!!</b></span>
              </div>
          </div>

        </div>

        <div className="step">
        
        <div className="box-step-4">
          <div className="box-step-4-config">
            <span className="box-step-4-text-1">{user.name} PARABÉNS POR CHEGAR ATÉ AQUI!</span>
            <span className="box-step-4-text-2"><b>O que eu vou te mostrar faz parte de uma ciência milenar que é utilizada pelos Judeus que são um dos povos mais ricos do mundo e por artistas.</b></span>
            <span className="box-step-4-text-3">Este conhecimento esta chegando para você também.</span>
            <span className="box-step-4-text-3"><b>AO NASCER SEUS PAIS TE DERAM UM NOME,<br /> E O UNIVERSO, OS NÚMEROS</b></span>
            <span className="box-step-4-text-3">Você não está aqui por acaso. Seu nome com sobrenome junto com sua data de nascimento fazem de você uma pessoa única.</span>
            <span className="box-step-4-text-3">Te mostra seu destino, suas aptidões, sua personalidade e também seus obstáculos.</span>
            <span className="box-step-4-text-3">A verdade sobre seu caminho está dentro de você!</span>
            <span className="box-step-4-text-3">E a chave é a Numerologia do seu nome e da sua data de nascimento.</span>
            <span className="box-step-4-text-3">Então, se você está aqui é porque seu momento chegou!</span>
            <span className="box-step-4-text-3">A hora de descobrir quem é você e qual seu caminho chegou!</span>
            <span className="box-step-4-text-3">Seu destino está escrito na sua data de nascimento. E a Numerologia interpreta isso e te mostra o caminho que precisa ser percorrido.</span>
            <span className="box-step-4-text-3">Este número te acompanha deste o nascimento até a morte. Por isso que descobrir seu destino te ajuda a saber qual energia você carrega para tomar decisões certas na vida.</span>
            <span className="box-step-4-text-3">Este número te explica seu comportamento e como reage diante situações ameaçadoras ou mesmo que precisa agir.</span>
            <span className="box-step-4-text-3">Seu número de destino, que vem da sua data de nascimento é como uma bússola que te orienta, que te ajuda a manter o foco.</span>
          </div>
        </div>


        {matriz.length > 0 && (
          <div className="box-triangle">
            <span className="title-triangle">Triângulo da vida:</span>
            <div className="triangle-preview">
              {/*<div className="button-triangle-preview">
              <Button size="lg" variant="outlined" onClick={handleZoomIn}>+</Button>
              <Button size="lg" variant="outlined" onClick={handleZoomOut}>-</Button>
        </div>*/}
              <div
                className="table-zoom"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: '0 0',
                  maxWidth: '90vw',
                }}
                ref={tableRef}
                onLoad={handleTableLoad}
              >
                <pre>{matriz[0]}</pre>
              </div>
            </div>
          </div>
        )}
            
          <div className="box-step-4">
          <div className="box-step-4-config">
            {matriz.length > 0 && (
                <div className="box-step-4-text-2">
                  <h2>Sequencias Negativas:</h2>
                  {matriz[2]}
                </div>
              )}
              </div></div>
              <div className="flex-box-7">
                <div>{destino && <span><b>Destino:</b> {destino[0]}</span>}</div>
                <div>{expressao && <span><b>Expressão:</b> {expressao[0]}</span>}</div>
                <div>{arcanosVibracaoRegente && <span><b>Arcano de vibração:</b> {arcanosVibracaoRegente[0]}</span>}</div>
                
                  <span>Quer saber mais sobre a numerologia?</span>
                  <span>Entenda <b>seu número das escolhas</b> psíquicas com a nossa assistente virtual Áurea <b>clicando no botão abaixo!!</b></span>
                  <span>Só leia se tiver um REAL INTERESSE em Destravar sua Vida e Carreira.</span>
                  <span>Descubra automaticamente com minha Assistente Virtual Áurea pelo WhatsApp o seu número das escolhas Psíquicas, clicando no botão abaixo!!</span>
                </div>

          </div>
      </Steps>
      <div className="navigation">
        {botoes(current)}
      </div>
    </div>
  );
}
