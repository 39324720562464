export function somar_valores_array(list_numbers) {
  var valor = 0;

  var lista = list_numbers.toString().split(""); //Transforma me array

  for (let i = 0; i < lista.length; i++) {
    // Loop par somar os valores
    valor += parseInt(lista[i]);
  }
  return valor.toString();
}
