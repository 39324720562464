import React from "react";
import profile1 from "./depoimento1.jpg";
import profile2 from "./depoimento2.jpg";
import profile3 from "./depoimento3.jpg";
import "./style.css";

const StarRating = ({ rating, author, profilePic }) => {
  let stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<span key={i}>⭐</span>);
    } else {
      stars.push(<span key={i}>✩</span>);
    }
  }
  return (
    <div className="star-rating">
    
    <div className="star-img">
      <img className="review-pic" src={profilePic} alt="Profile" />
      <div className="review-author">{author}</div>

    </div>
    <div className="star-symbol">{stars}</div>
    </div>
  );
};

const Review = ({ author, number, profilePic, rating, reviewText }) => {
  return (
    <div className="review-box">
        <div className="review-text">
          <span>{reviewText}</span>
        </div>
        <StarRating rating={rating} author={author} profilePic={profilePic} />
    </div>
  );
};

function App() {
  return (
    <div className="app">
      <Review
        author="Elias Henrique F"
        //number="1"
        profilePic={profile1}
        rating={5}
        reviewText="Nossa, você apresentou o que está acontecendo na minha vida!"
      />
      <Review
        author="Juliana Helena L"
        //number="2"
        profilePic={profile2}
        rating={5}
        reviewText="Prof. Max tenho certeza que estou agora no caminho correto."
      />
      <Review
        author="Maria Sofia C"
        //number="3"
        profilePic={profile3}
        rating={5}
        reviewText="Passei a vida toda em ciclos repetitivos e agora sei o que aconteceu."
      />
    </div>
  );
}

export default App;