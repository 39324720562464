export function valor_unitario(temp) {
  switch (temp) {
    case 10:
      return 1;
    case 11:
      return 2;
    case 12:
      return 3;
    case 13:
      return 4;
    case 14:
      return 5;
    case 15:
      return 6;
    case 16:
      return 7;
    case 17:
      return 8;
    case 18:
      return 9;
    default:
      return temp;
  }
}
