import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { sequencias } from "./sequencias.js";
import { valor_unitario } from "./valor_unitario.js";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function triangulo(caracteres, temp) {
  var nome_matriz = [];
  var nome = [];
  var toda_a_sequencia = [];
  var sequencia_negativa = [];

  nome_matriz.push("<table>");
  var nome_matriz_react = [];
  var temp_nome_matriz_react = [];

  // Adicionando a primeira linha do triângulo
  nome_matriz.push('<tr style="font-size:0.6vw">');
  //html_matriz += '<tr style="font-size:0.9vw">';
  caracteres.map((currElement, index) => {
    if (index < caracteres.length - 1) {
      nome_matriz.push("<td>" + currElement + "</td><td></td>");
      temp_nome_matriz_react.push(currElement);
      temp_nome_matriz_react.push("");

      //html_matriz += "<td>" + currElement + "</td><td></td>";
    }
    if (index == caracteres.length - 1) {
      nome_matriz.push("<td>" + currElement + "</td>");
      temp_nome_matriz_react.push(currElement);
    }
  });
  nome_matriz.push("</tr>");
  nome_matriz_react.push(temp_nome_matriz_react);

  temp_nome_matriz_react = [];
  var temp_nome_matriz_react2 = [];

  // Adicionando a segunda linha do triangulo
  nome_matriz.push('<tr style="font-size:0.6vw">');

  temp.map((currElement, index) => {
    if (index < temp.length - 1) {
      nome_matriz.push("<td>" + currElement + "</td><td></td>");
      temp_nome_matriz_react.push(currElement);
      temp_nome_matriz_react.push("");
      temp_nome_matriz_react2.push(currElement);
      //html_matriz += "<td>" + currElement + "</td><td></td>";

      toda_a_sequencia.push(temp[index] + parseInt(temp[index + 1]));
    }
    if (index == temp.length - 1) {
      nome_matriz.push("<td>" + currElement + "</td>");
      temp_nome_matriz_react.push(currElement);
      temp_nome_matriz_react2.push(currElement);
    }
  });
  nome_matriz.push("</tr>");
  nome_matriz.push("</table>");

  //Criando array de arrays
  nome_matriz_react.push(temp_nome_matriz_react);

  var matriz_de_numeros = [];
  temp_nome_matriz_react = [];
  var contador = 1;

  // Função que calcula toda a tabela a partir da primeira linha de valores
  function map_table(array_input, count) {
    var valor_temporario = "";
    var linha_table = [];
    var LinhaPura = [];
    var cores = [];

    // Coloca as colunas vazias antes de cada linha
    for (let i = 0; i < count; i++) {
      linha_table.push("");
    }

    // Calcula os valores de cada linha
    array_input.map((currElement, index) => {
      if (index < array_input.length - 1) {
        valor_temporario = valor_unitario(
          parseInt(array_input[index]) + parseInt(array_input[index + 1])
        );

        linha_table.push(valor_temporario);

        linha_table.push("");

        LinhaPura.push(
          valor_unitario(
            parseInt(array_input[index]) + parseInt(array_input[index + 1])
          )
        );

        toda_a_sequencia.push(
          array_input[index] + parseInt(array_input[index + 1])
        );
      }
    });

    // Coloca as colunas vazias depois de cada linha
    for (let i = 0; i < count - 1; i++) {
      linha_table.push("");
    }
    //Aumenta o valor a quantidade de colunas vazias a medidas que passa as linhas
    count = count + 1;

    //Retorna uma marcador para colunas vazias, um array com os valores puros de cada linha para ser utilizado na linha seguinte e um array para ser utilizado na contrução da tabela
    return [count, LinhaPura, linha_table];
  }

  var count = 1; // Contador de colunas antes de cada linha
  var temp3 = []; // Variavel temporaria para ser utilizada na recursão

  for (let i = 0; i < temp_nome_matriz_react2.length; i++) {
    if (count == 1) {
      temp3 = map_table(temp_nome_matriz_react2, count);
      nome_matriz_react.push(temp3[2]);
      count = temp3[0];
    }
    if (1 < count && count < temp_nome_matriz_react2.length) {
      temp3 = map_table(temp3[1], count);
      nome_matriz_react.push(temp3[2]);
      count = temp3[0];
    }
  }

  function color_function(array, index, num) {
    var cor_temp = "";

    var cor1 = [];
    var cor2 = [];
    var cor3 = [];
    var listar_todas_seq = [];

    cor1 = sequencias(
        parseInt(array[index - 4]).toString() +
        parseInt(array[index - 2]).toString() +
        parseInt(array[index]).toString()
    );

    var cor_0_0 = cor1[0];
    var cor_1_0 = cor1[1];
    var cor_2_0 = cor1[2];

    cor2 = sequencias(
        parseInt(array[index - 2]).toString() +
        parseInt(array[index]).toString() +
        parseInt(array[index + 2]).toString()
    );

    var cor_0_1 = cor2[0];
    var cor_1_1 = cor2[1];
    var cor_2_1 = cor2[2];

    cor3 = sequencias(
        parseInt(array[index]).toString() +
        parseInt(array[index + 2]).toString() +
        parseInt(array[index + 4]).toString()
    );

    var cor_0_2 = cor3[0];
    var cor_1_2 = cor3[1];
    var cor_2_2 = cor3[2];

    var cor_letra_tabela = "#212121";
    var lista_sequencias_positivas = [];
    var lista_sequencias_negativas = [];

    //console.log(cor_0_2);
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#2196f3" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("1");
    }

    if (cor_0_0 == "#2196f3" && cor_0_1 == "#2196f3" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("2");
    }

    if (cor_0_0 == "#2196f3" && cor_0_1 == "#f44336" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("3");
    }

    if (cor_0_0 == "#2196f3" && cor_0_1 == "#f44336" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("4");
    }

    if (cor_0_0 == "#f44336" && cor_0_1 == "#2196f3" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("5");
    }

    if (cor_0_0 == "#f44336" && cor_0_1 == "#2196f3" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("6");
    }

    if (cor_0_0 == "#f44336" && cor_0_1 == "#f44336" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("7");
    }

    if (cor_0_0 == "#f44336" && cor_0_1 == "#f44336" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("8");
    }

    // IF DAS CORES PRETAS com positivas
    if (cor_0_0 == "#212121" && cor_0_1 == "#212121" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#212121";
      //console.log("9");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#212121" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("10");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#2196f3" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_1);
      //console.log("11");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#2196f3" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("12");
    }
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#212121" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_0);
      //console.log("13");
    }
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#212121" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_2);
      //console.log("14");
    }
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#2196f3" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#2196f3";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_positivas.push(cor_2_1);
      //console.log("15");
    }

    // IF DAS CORES PRETAS com NEGATIVAS
    if (cor_0_0 == "#212121" && cor_0_1 == "#212121" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#212121";
      //console.log("16");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#212121" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("17");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#f44336" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_1);
      //console.log("18");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#f44336" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("19");
    }
    if (cor_0_0 == "#f44336" && cor_0_1 == "#212121" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_0);
      //console.log("20");
    }
    if (cor_0_0 == "#f44336" && cor_0_1 == "#212121" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("21");
    }
    if (cor_0_0 == "#f44336" && cor_0_1 == "#f44336" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#f44336";
      lista_sequencias_negativas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      //console.log("22");
    }

    // IF DAS CORES PRETAS com NEGATIVAS E POSITIVAS
    if (cor_0_0 == "#212121" && cor_0_1 == "#f44336" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_2);
      lista_sequencias_negativas.push(cor_2_1);
      //console.log("23");
    }
    if (cor_0_0 == "#212121" && cor_0_1 == "#2196f3" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("24");
    }
    if (cor_0_0 == "#f44336" && cor_0_1 == "#212121" && cor_0_2 == "#2196f3") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_2);
      lista_sequencias_negativas.push(cor_2_0);
      //console.log("25");
    }
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#212121" && cor_0_2 == "#f44336") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_2);
      //console.log("26");
    }
    if (cor_0_0 == "#2196f3" && cor_0_1 == "#f44336" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_0);
      lista_sequencias_negativas.push(cor_2_1);
      //console.log("27");
    }
    if (cor_0_0 == "#f44336" && cor_0_1 == "#2196f3" && cor_0_2 == "#212121") {
      cor_letra_tabela = "#ff6d00";
      lista_sequencias_positivas.push(cor_2_1);
      lista_sequencias_negativas.push(cor_2_0);
      //console.log("28");
    }

    if (lista_sequencias_positivas != "") {
      lista_unica_seq_positiva.push(lista_sequencias_positivas);
    }
    if (lista_sequencias_negativas != "") {
      //lista_unica_seq_negativa.push(lista_sequencias_negativas);

      lista_sequencias_negativas.map((currElement, index) => (
        //console.log(currElement)
        lista_unica_seq_negativa.push(currElement)
      ))

      sequencia_negativa.push(lista_sequencias_negativas);
    }

    return [
      cor_letra_tabela,
      lista_sequencias_positivas,
      sequencia_negativa,
      listar_todas_seq,
    ];
  }

  var lista_unica_seq_positiva = [];
  var lista_unica_seq_negativa = [];

  var mapeamento_sequencias = "";




  var nome_matriz_dense2 = (
    <div>
    <Box>
          <Table
            size="small"
            aria-label="triangulo_da_vida"
            sx={{
              "td,  th": { m: 0.1, p: 0.1 },
              
            }}
          >
            <TableBody>
              {nome_matriz_react.map((currElement, index) => (
                <TableRow
                  key={nome_matriz_react.index}
                  //sx={{ " td,  th": { border: 1 } }}
                >
                  {currElement.map((val2, index2) => (
                    <TableCell
                      align="justify"
                      sx={{ color: color_function(currElement, index2, 0)[0] }}
                    >
                      {val2}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
            </Box>
    </div>
  );

/*var sequencias_negativas_ordenadas = valores_unicos(sequencia_negativa).filter(function (elem, index, self) {
  return index === self.indexOf(elem);
}).sort()*/

var sequencias_negativas_ordenadas = valores_unicos(lista_unica_seq_negativa).filter(function (elem, index, self) {
  return index === self.indexOf(elem);
}).sort()

var ordenando = lista_unica_seq_negativa.filter(function (elem, index, self) {
  return index === self.indexOf(elem);
}).sort()

var array_sequencia_negativa = ordenando;

ordenando = ordenando.map((val2, index2) => (
    <Table
    size="small"
    aria-label="triangulo_da_vida"
    sx={{
      "td,  th": {  m: 0.1, p: 0.1 },
    }}
    >
      <TableRow>
        <TableCell
          align="justify"
        >
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
            {val2}
          </Typography>
        </TableCell>
      </TableRow>
    </Table>
));

sequencias_negativas_ordenadas = sequencias_negativas_ordenadas.map((val2, index2) => (
  <Box sx={{ mx: 'auto', width: '100%', maxWidth: '100%' }}>
    <Table
    size="small"
    aria-label="triangulo_da_vida"
    sx={{
      "td,  th": { maxWidth: "1rem",  m: 0.1, p: 0.1 },
      px: 1
    }}
    >
      <TableRow>
        <TableCell
          align="justify"
        >
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
            {val2}
          </Typography>
        </TableCell>
      </TableRow>
    </Table>
  </Box>
));

  return [nome_matriz_dense2, toda_a_sequencia, ordenando, array_sequencia_negativa];
}

function valores_unicos(val) {
  var temp_sequencias = [];
  val.map((currElement, index) => temp_sequencias.push(currElement[0]));

  return temp_sequencias;
}
