import * as React from "react";
import {

  Typography,
} from "@mui/material";
import { arcane } from "./arcane.js";
export function arcanos_table(temp:any) {
  // Accordion


  var arcano_temp = [];
  var length_temp = temp.length;
  temp.map((currElement, index) => {
    if (index < length_temp - 1) {
      arcano_temp.push(temp[index] + temp[index + 1]);
    }
  });
  var arcano_tabela_input = arcano_temp
    .filter((v, i) => arcano_temp.indexOf(v) === i)
    .sort();
  arcanos = "";
  arcano_tabela_input.map((currElement, index) => {
    arcanos += "<Typography>" + arcane(currElement) + "</Typography>";
  });

  let App = () => {
    var temp_tabela = arcano_tabela_input;
    return (
      <Typography>
        {arcano_tabela_input.map((e, i) => {
          return (
            <li key={e}>
              {arcane(e)}
              <hr></hr>
            </li>
          );
        })}
      </Typography>
    );
  };

  var arcanos = (
    <div>


    </div>
  );

  return [arcanos, arcano_temp, arcano_tabela_input];
}
