export function calculo_arcanos_vibracao_regente(
  toda_a_sequencia,
  arcano_temp,
  input_nascimento,
  arcano_tabela_input
) {
  console.log(input_nascimento)
  var today = new Date();
  //var birth_date = datetime.strptime(input_nascimento, "%Y-%m-%d");
  var birth_date = new Date(input_nascimento);
  var age = today.getFullYear() - birth_date.getFullYear();
  var full_year_passed =
    (today.getMonth(), today.getDay()) <
    (birth_date.getMonth(), birth_date.getDay());
  if (today.getMonth() < birth_date.getMonth()) {
    if (today.getDay() < birth_date.getDay()) {
      age -= 1;
    }
  }

  var data_atual = today;
  var anos_de_vida = age;
  var idx_arcano_vibr = parseInt((anos_de_vida * arcano_temp.length) / 90);
  if (idx_arcano_vibr >= arcano_temp.length) {
    idx_arcano_vibr = arcano_temp.length - 1;
  }

  var arcano_vibracao = arcano_temp[idx_arcano_vibr];
  /*print(
    '<input type="hidden" name="id_arc_atual" id="id_arc_atual" value="' +
      idx_arcano_vibr +
      '">'
  );
  print(
    '<input type="hidden" name="num_arc_atual" id="num_arc_atual" value="' +
      arcano_temp[idx_arcano_vibr].toString() +
      '">'
  );*/

  //var arcano_regente = "Arcano regente: " + toda_a_sequencia[-1];
  var arcano_regente = "Arcano regente: " + toda_a_sequencia.at(-1);
  //console.log(idx_arcano_vibr);
  //console.log(toda_a_sequencia.at(-1));
  return [arcano_vibracao, arcano_regente, idx_arcano_vibr];
}
