export function sequencias(val) {
  switch (val) {
    case "111":
      return [
        "#f44336",
        "negativo",
        "Sequência 111: Com a iniciativa e a determinação: a pessoa fica limitada, perdendo a coragem de se aventurar em algo novo. Pode, também, ficar um longo período inativo, desempregado ou mesmo impotente para realizar o que quer que seja, permanecendo nesse estado o tempo que durar o Arcano que domina o período. Tal sequência pode ainda provocar doenças cardíacas de vários tipos.",
        111,
      ];
    case "222":
      return [
        "#f44336",
        "negativo",
        "Sequência 222: Com a autoconfiança: esta configuração provoca timidez e indecisão, podendo levar o seu possuidor a ser subjugado por aqueles mais próximos, sejam eles amigos, sócios, colegas de trabalho ou simplesmente conhecidos. Faz a pessoa perder a autoestima, limitando-a quanto a seus projetos e realizações. Pode, ainda, causar doenças que provocam dependência química.",
        222,
      ];
    case "333":
      return [
        "#f44336",
        "negativo",
        "Sequência 333: Com a comunicação: a pessoa torna-se incompreendida, falta diálogo sobre tudo e com todos, principalmente com colegas de trabalho e com o/a companheiro/a. tem dificuldade de se impor em seus projetos e é difícil convencer as pessoas. Esta sequência pode ainda causar doenças respiratórias ou de articulações.",
        333,
      ];
    case "444":
      return [
        "#f44336",
        "negativo",
        "Sequência 444: Com o trabalho: torna difícil qualquer realização profissional. Normalmente são mal remunerados e as perspectivas profissionais são difíceis ou tem dificuldade em se manter no emprego ou se dar bem em qualquer atividade. Pode, ainda, causar doenças reumáticas e arteriais.",
        444,
      ];
    case "555":
      return [
        "#f44336",
        "negativo",
        'Sequência 555: Com a instabilidade financeira e pessoal: pode causar mudanças não desejadas de casa, de emprego, meio social, de país e de parceiro. Sob esta influência, a pessoa tem muitos "altos e baixos", não se fixando profissionalmente, sempre à procura de melhores oportunidades, sem, contudo, as encontrar. Pode, também, causar "fuga" do meio social em que habita e contrair doenças superficiais e destrutivas, ou seja, doenças de pele.',
        555,
      ];
    case "666":
      return [
        "#f44336",
        "negativo",
        "Sequência 666: Com os afetos e sentimentos: causa decepções com amigos, sócios, colegas de trabalho, parentes e até com o cônjuge (namorado ou companheiro), que não o/a compreende em seus propósitos e sentimentos. Em vista dessas decepções, a pessoa pode desenvolver algum tipo de doença cardíaca.",
        666,
      ];
    case "777":
      return [
        "#f44336",
        "negativo",
        "Sequência 777: Com o medo e a intolerância: faz com que a pessoa se afaste de tudo e de todos; pode levar ao desmando, transformando as pessoas em seres dependentes, vaidosos, arrogantes e, consequentemente, vítima da própria intolerância. Provoca sentimentos de solidão, desânimo, doenças nervosas, dependentes e, em vista disso, possivelmente algum tipo de câncer.",
        777,
      ];
    case "888":
      return [
        "#f44336",
        "negativo",
        "Sequência 888: Com problemas emocionais e também financeiros: esta sequência torna a pessoa arredia, afastando-a das atividades sociais. Caso não seja evoluído espiritualmente, pode se descontrolar emocionalmente com muita facilidade. Sob esta vibração, a pessoa pode oscilar entre a riqueza e a pobreza e, como consequência, pode contrair graves doenças ou mesmo ter algum de seus dependentes com tais sintomas.",
        888,
      ];
    case "999":
      return [
        "#f44336",
        "negativo",
        "Sequência 999: Com relação a finanças: a pessoa passa por sérios problemas financeiros, tem perda de bens (móveis e imóveis), as empresas fracassam e passa por vários tipos de provações provocados por longos períodos de paralisação. Tudo isto pode afetar o sistema nervoso e o coração.",
        999,
      ];
    default:
      return ["#212121", "", ""];
    //return null;
  }
}
