import { somar_valores_array } from "./somar_valores_array.js";
export function calculo_destino(nascimento_split) {
  var destino =
    nascimento_split[2].toString() +
    nascimento_split[1].toString() +
    nascimento_split[0].toString();
  var num_destino = 0;
console.log(destino);
  Array.from(destino).map((currElement, index) => {
    num_destino = num_destino + parseInt(currElement);
  });

  var num_destino_original = num_destino;

  for (let i = 1; i < 2; i = i + 0) {
    if (num_destino != 11 && num_destino != 22) {
      if (Array.from(num_destino.toString()).length > 1) {
        num_destino = somar_valores_array(num_destino);
      } else {
        i++;
      }
    } else {
      i++;
    }
  }
console.log(num_destino);
  var soma_numeros_ano = somar_valores_array(nascimento_split[0].toString());
  var soma_numeros_mes = somar_valores_array(nascimento_split[1].toString());
  var soma_numeros_dia = somar_valores_array(nascimento_split[2].toString());

  var variavel_temporaria = 0;

  if (num_destino == 2 || num_destino == 4) {
    if (soma_numeros_ano.toString().length > 1) {
      for (let i = 1; i < 2; i = i + 0) {
        soma_numeros_ano = somar_valores_array(soma_numeros_ano);
        if (soma_numeros_ano.toString().length == 1) {
          i++;
        }
      }
      console.log(soma_numeros_ano);
    }
    if (soma_numeros_mes.toString().length > 1) {
      for (let i = 1; i < 2; i = i + 0) {
        soma_numeros_mes = somar_valores_array(soma_numeros_mes);
        if (soma_numeros_mes.toString().length == 1) {
          i++;
        }
      }
    }
    if (soma_numeros_dia.toString().length > 1) {
      for (let i = 1; i < 2; i = i + 0) {
        soma_numeros_dia = somar_valores_array(soma_numeros_dia);
        if (soma_numeros_dia.toString().length == 1) {
          i++;
        }
      }
    }
  }

  var num_calculo_secundario =
    parseInt(soma_numeros_ano) +
    parseInt(soma_numeros_mes) +
    parseInt(soma_numeros_dia);

  if (num_calculo_secundario == 11 || num_calculo_secundario == 22) {
    num_destino = num_calculo_secundario;
  }

  return [num_destino, num_destino_original];
}
